
var common_dir = 'https://icms.opsun.com/icms/data/theme/default/resources';

// Librairie de fonctions Imarcom
//=require lib/functions.js

// Bootstrap
//=require ../node_modules/popper.js/dist/umd/popper.min.js
//=require ../node_modules/bootstrap/dist/js/bootstrap.min.js

// Public_forms
//=require jquery/jquery.publicForm.js
//=require lib/form_handler.js
//=require jquery/jquery.capslockstate.js
//=require jquery/jquery.imcSelect.js
//=require ../node_modules/jquery-simple-pass-meter/jquery.simplePassMeter.js
//=require ../node_modules/jquery.maskedinput/src/jquery.maskedinput.js
//=require ../node_modules/qtip2/dist/jquery.qtip.min.js
//=require ../node_modules/blueimp-file-upload/js/jquery.iframe-transport.js
//=require ../node_modules/blueimp-file-upload/js/vendor/jquery.ui.widget.js
//=require ../node_modules/blueimp-file-upload/js/jquery.fileupload.js
//=require ../node_modules/jquery-autotab/js/jquery.autotab.min.js

// openModal
//=require ../vendor/imarcom/open-modal/js/openModal.js

// Slideshows
//=require jquery/jquery.imarcomSlideshow.js

// cubePortfolio
//=require jquery/jquery.cubeportfolio.js

// Map_pages
//=require jquery/jquery.imarcomGmap.js

// Other tools
//=require ../node_modules/jquery.easing/jquery.easing.min.js
//=require ../node_modules/jquery.easing/jquery.easing.compatibility.js
//=require jquery/jquery.imarcomLoader.js
//=require jquery/jquery.imarcomShrinkableHeader.js
//=require lib/ajax_answer.js
//=require ../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js

// Client
